<template>
  <b-card class="w-100">
    <div class="d-flex align-items-center justify-content-between">
      <strong><feather-icon size="18" icon="LayoutIcon" /> Board 4.3 Aufraugen</strong>
      <div>
        <b-dropdown
          class="ml-50"
          size="sm"
          variant="light"
          no-caret
          dropleft
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="14" />
          </template>
          <b-dropdown-item>Open the board</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <h2 class="mt-1 h1">
      63/78 <small class="text-muted">task completed</small>
    </h2>
    <div class="mt-2 d-flex flex-column" style="gap: 10px;">
      <div>
        <div>
          <div class="d-flex justify-content-between align-items-end mb-50">
            <small>32 tasks are processing</small>
            <strong class="h4">48% Done</strong>
          </div>
          <div class="w-100 position-relative" style="padding: 8px">
            <div v-b-tooltip.hover title="45% completed" class="rounded-pill position-absolute bg-success" style="width: 50%; top: 0; left: 0; bottom: 0; box-shadow: 0px 0px 4px var(--success);" />
            <div v-b-tooltip.hover title="30% pending" class="rounded-pill position-absolute bg-warning" style="width: 40%; top: 0; left: 50%; bottom: 0; box-shadow: 0px 0px 4px var(--warning);" />
            <div v-b-tooltip.hover title="10% cancelled" class="rounded-pill position-absolute bg-danger" style="width: 10%; top: 0; left: 90%; bottom: 0; box-shadow: 0px 0px 4px var(--danger);" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div>
            <div class="bg-success rounded-pill d-inline-block" style="width: 6px; height: 6px;" /> <small>12 Completed</small>
          </div>
          <div>
            <div class="bg-warning rounded-pill d-inline-block" style="width: 6px; height: 6px;" /> <small>27 pending</small>
          </div>
          <div>
            <div class="bg-danger rounded-pill d-inline-block" style="width: 6px; height: 6px;" /> <small>2 Cancelled</small>
          </div>
        </div>
        <div class="text-center">
          <feather-icon icon="CheckCircleIcon" class="text-success" size="18" />
          <p>Evething fine</p>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  name: 'BoardStatItem',
  components: {
    BDropdown,
    BDropdownItem,
  },
}
</script>
